<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
  
        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body pb-0">
                <div class="header_section">
                  <h4 class="menu_header">Payments</h4>
                  <div class="right-btnGroup" v-if="user.role.id == 1 || user.role.id == 2">
                    <h1>Export Payments</h1>
                    <div class="btn-toolbar">
                      <button class="btn btn-purple" @click="exportOrder('xlsx')" :disabled="searchingType =='xlsx' ? true : false">
                        <span v-if="searchingType == 'xlsx'"><span v-html="searchspinner"></span></span> Excel
                      </button>
                    </div>
                  </div>
                </div>
                <div class="search_section mb-0">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group has-search">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="paymentSearch" @Keyup="searchOrders()" class="form-control"
                          placeholder="Search">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mr-2" @click="searchOrders('search')">
                          <span v-if="searchingType == 'search'"><span v-html="searchspinner"></span></span> Search
                        </button>
                      </div>
                    </div>
      
                    <div class="col-md-2">
                      <div class="form-group has-search">
      
                        <button class="w-100 btn btn-dgrey" @click="clearSearch">
                          <span v-if="searchingType == 'clear'"><span v-html="searchspinner"></span></span> Clear Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="row">
                        <div class="col-md-3 pad-r-0">
      
                          <Multiselect v-model="storeSearch" @change="searchOrders()" :close-on-select="true"
                            :options="strsoptions" :custom-label="name" :searchable="true"
                            :placeholder="'Stores'" label="name" track-by="name"></Multiselect>
                        </div>
                        <div class="col-md-3 pad-r-0">
                          <select class="form-control" v-model="searchChannel" @change="searchOrders()">
                            <option value=""> --Select Channel--</option>
                            <option value="CALLCENTER">Callcenter</option>
                            <option value="ONLINE">Online</option>
                           
                          </select>
                        </div>
                        <div class="col-md-3 pad-r-0">
                          <div class="">
                            <Datepicker placeholder="Start Date" v-model="start" utc autoApply
                              :format="customFormatter"></Datepicker>
                          </div>
      
                        </div>
                        <div class="col-md-3">
                          <div class="">
                            <Datepicker placeholder="End Date" v-model="end" autoApply utc :format="customFormatter"
                              :minDate="minDate"></Datepicker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <!--Status menu start-->
                  <div class="St-menu">
                    <div class="status_btn_group"></div>
                    <button :class="[{'active': statusType == ''},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('')">
                      <span >All </span>
                    </button>
                    <button :class="[{'active': statusType == 'PLACED'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('PLACED')">
                      <span >Placed <small> ( {{ paymentPlaced.total_payment ? paymentPlaced.total_payment : 0 }} , {{ paymentPlaced.currency ? paymentPlaced.currency : 'KES' }} {{ formatPrice(paymentPlaced.total_amount ? paymentPlaced.total_amount : 0) }})</small></span>
                    </button>
                    <button :class="[{'active': statusType == 'FAILED'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('FAILED')">
                      <span >Failed  <small>( {{ paymentFailed.total_payment ? paymentFailed.total_payment: 0 }} , {{ paymentFailed.currency ? paymentFailed.currency : 'KES' }} {{ formatPrice(paymentFailed.total_amount ? paymentFailed.total_amount : 0) }})</small></span>
                    </button>
                    <button :class="[{'active': statusType == 'COMPLETED'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('COMPLETED')">
                      <span >Completed <small>( {{ paymentComplete.total_payment ? paymentComplete.total_payment : 0 }}, {{ paymentComplete.currency ? paymentComplete.currency : 'KES' }} {{ formatPrice(paymentComplete.total_amount ? paymentComplete.total_amount : 0) }}</small>)</span>
                    </button>
                  </div>
                  <!--Status menu End-->
                </div>
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Reference</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Store</th> 
                        <th scope="col">Channel</th> 
                        <th scope="col"> Total </th>
                        <th scope="col">Payment Method</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Date</th>
        
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="(payment,oindx) in  payments" :key="oindx">
                        <td>
                          <a class="title_text" href="javascript:void(0)" data-toggle="modal"
                            :data-target="'#paymentDetail_'+payment.id"> {{payment.transaction_id}}</a>
                        </td>
                        <td>
                          <span>{{payment.customer_name}}</span> <br />
                        </td>
                        <td>
                          {{payment.store_name}} <br>
                        </td>
                        <td>
                          <span v-if="payment.channel "> {{ payment.channel }}</span>
                          <span v-else>N/A</span>
                        </td>
            
  
                     
                        <td class="td-price">
                          {{payment.currency}} {{formatPrice(payment.amount)}}
                        </td>
                        <td>
                          <span v-if="payment.method">{{ payment.method }}</span> <span v-else>N/A</span>
                        </td>
                        <td>
                          <span v-if="payment.status">
                            <span :class="[{'active': payment.status.toLowerCase() === 'completed'},{'disabled':payment.status.toLowerCase() === 'failed'},{'pending': payment.status.toLowerCase() === '' || payment.status.toLowerCase()  == 'pending' || payment.status.toLowerCase()  == 'placed'}]">
                              <strong>{{payment.status ? payment.status : 'PENDING'}}</strong>
                            </span>
                          </span>
                          <br><br>
                          <span v-if="!payment.status || payment.status.toLowerCase()  == 'placed' ">
                            <button class="btn btn-sm mt-1 mr-1 btn-default custom_btn  t_btn custon_orange"
                              @click="checkStatus(payment.transaction_id)"><span v-if="statusSpinner == payment.transaction_id"> <i
                              class="fa fa-spinner fa-spin"></i> Checking..</span><span v-else>Check
                              Status</span>
                            </button>
                          </span> 
                          <!-- button to manually complete a payment -->
                          <span v-if="user.role.id == 1  && payment.status.toLowerCase()  == 'failed'">
                            <button class="btn btn-sm mt-1 btn-default t_btn custom_btn custon_orange" data-toggle="modal" :data-target="`#manualcompletion_${payment.id}`">
                              <span>Manually Complete</span>
                            </button>
                          </span>
  
                          <!-- modal for to enter method -->
                          <div class="modal fade" :id="`manualcompletion_${payment.id}`" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h2 class="modal-title" id="exampleModalLongTitle">Manually Completing payment (#{{ payment.channel_display_id }})</h2>
                                  <button type="button" class="close" :id="`close_manualcompletion_${payment.id}`" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1">Payment Method</label>
                                    <select class="form-control" v-model="paymentmethod[payment.id]">
                                      <option value="[]">--Select Method--</option>
                                      <option value="MpesaKE">Mpesa</option>
                                      <option value="Visa">Visa</option>
                                      <option value="AirtelMoney">Airtel Money</option>
                                    </select>
                                  </div>
                                  <div class="form-group">
                                    <label for="exampleInputPassword1">Payment ID</label>
                                    <input type="text" class="form-control" v-model="paymentId[payment.id]">
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Dismiss</button>
                                  <button type="button" class="btn btn-success" @click="manualyComplete(payment.id)" >
                                    <span v-if="errorLoading == payment.id"><i class="fa fa-spinner fa-spin"></i> </span>  Complete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span class="text-muted">{{formatDate(payment.created_at,3)}}</span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="9">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                  <option :value="100">100</option>
                                </select>
                              </li>
  
                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                              @pagechanged="onPageChange" />
  
                          </div>
  
                        </td>
                      </tr>
                    </tfoot>
                  </table>
  
                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>
  
              </div>
            </div>
  
          </div>
               
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import moment from 'moment';
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
  import Pagination from '@/pages/pagination/pagination.vue';
  import Multiselect from '@vueform/multiselect';
  import { useToast } from "vue-toastification";
  const toast = useToast();
  export default {
      components: {
          Pagination,
          Multiselect
      },
      data() {
          return {
              statusType: '',
              payments: [],
              start: '',
              end: '',
              paymentSearch: '',
              stores: [],
              orderItem: [],
              searchingType: '',
              loading: false,
              page: 1,
              orderDelay: '',
              totalPages: 0,
              totalRecords: 0,
              recordsPerPage: 50,
              debounce: null,
              storeSearch: '',
              paymentStatus: '',
              strsoptions: [],
              itemPosId: '',
              enterpageno: '',
              spinner: '',
              exportType: '',
              exportData: [],
              statusSpinner: false,
              searchspinner: "<i class='fa fa-spinner fa-spin '></i>",
              paymentmethod:[],
              paymentId:[],
              errorLoading: false,
              showPosDetail: 1,
              searchChannel:'',
              paymentComplete: [],
              paymentPlaced:[],
              paymentFailed:[]
          }
      },
      beforeMount: async function () {
          this.getPayments(true);
          this.getBranches();
      },
      computed: {
          minDate() {
              return this.start;
          }
      },
      methods: {
          async manualyComplete(paymentid){
            this.errorLoading = paymentid
            try {
              const payload ={
                'payment_method': this.paymentmethod[paymentid],
                'payment_id': this.paymentId[paymentid]
              }
              const url =`${this.dashBaseUrl}/update-payment/${paymentid}/${this.merchantID}`
              const res = await this.axios.post(url,payload,this.apiRequestConfig())
              if(res.status === 201){
                this.errorLoading = false
                document.getElementById(`close_manualcompletion_${paymentid}`).click()
                toast.success("payment manually completed successfully")
              }
            } catch (error) {
              this.errorLoading = false
              document.getElementById(`close_manualcompletion_${paymentid}`).click()
              if(error.response && error.response.status === 400){
                let errorMsg = ''
                const reserrors = error.response.data.errors;
                for(let err=0; err < reserrors.length; err++){
                  errorMsg +=reserrors[err]+'\n'
                }
                toast.error(errorMsg)
              }else if(error.response && error.response.status === 401 ){
                this.$router.push({
                        name: 'login'
                }); 
              }else{
                toast.error("unable to manully complete payment")
              }
              
            }
          },
          filterPaymentStatus(type){
            this.paymentStatus = type
            this.statusType = type
            this.getPayments(true);
          },
          clearSearch() {
              this.searchingType = 'clear';
              this.paymentStatus = '';
              this.storeSearch = '';
              this.paymentSearch='';
              this.start = '';
              this.end = '';
              this.exportType = '';
              this.getPayments(true);
          },
          customFormatter(date) {
              return moment(date).format('YYYY-MM-DD');
          },
          exportOrder(type) {
              this.searchingType = type;
              this.exportType = type;
              this.getPayments(false);
          },
          searchOrders(type = '') {
              if (type !== '') {
                  this.searchingType = type;
              }
              clearTimeout(this.debounce);
              this.debounce = setTimeout(() => {
                  this.getPayments(true);
              }, 1000)
          },
          getPayments(loading) {
              this.loading = loading;
              this.statusType = this.paymentStatus
              let url = `${this.dashBaseUrl}/payments/${this.merchantID}`;
              url = new URL(url);
              if (this.exportType === '') {
                  url.searchParams.set('page', this.page);
                  url.searchParams.set('size', this.recordsPerPage);
              }
              if (this.paymentSearch !== '') {
                  url.searchParams.set('search', this.paymentSearch);
              }
              if (this.storeSearch !== '') {
                  url.searchParams.set('store_id', this.storeSearch);
              }
              if (this.start !== '') {
                  this.start = moment(this.start).format('YYYY-MM-DD');
                  url.searchParams.set('start_date', this.start);
              }
              if (this.end !== '') {
                  this.end = moment(this.end).format('YYYY-MM-DD')
                  url.searchParams.set('end_date', this.end);
              }
              if (this.paymentStatus !== '') {
                  url.searchParams.set('payment_status', this.paymentStatus);
              }
              if(this.searchChannel !=''){
                url.searchParams.set('channel', this.searchChannel);
              }
              if(this.exportType !=''){
                url.searchParams.set('export_data', true);
              }
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              this.axios.get(url, config).then((response) => {
                  if (response.status === 200) {
                      if (this.exportType !== '') {
                          this.exportData = response.data;
                          const opayments = response.data.data.payments;
                          const orderDetails = [];
                              for (const opayment of Object.values(opayments)) {
                                  orderDetails.push({
                                      'Reference': opayment.transaction_id,
                                      'Customer': opayment.customer_name,
                                      'Store': opayment.store_name,
                                      'Channel':opayment.channel ? opayment.channel : 'N/A',
                                      'Amount': opayment.currency +" " +this.formatPrice(opayment.amount),
                                      'Payment Method': opayment.method ? opayment.method : 'N/A',
                                      'Payment Status': opayment.status,
                                      'Date': this.formatDate(opayment.created_at,3)
                                  })
                          }
                          const fileName = Date.now() + '_payments'
                          this.exportExcel(orderDetails, fileName)
                          this.exportType = '';
                      } else {
                          this.payments = response.data.data.payments.data;
                          this.paymentComplete = response.data.data.payment_complete
                          this.paymentPlaced = response.data.data.placed_payments
                          this.paymentFailed = response.data.data.failed_payments
                          this.totalPages = Math.ceil(response.data.data.payments.total / this.recordsPerPage)
                          this.totalRecords = response.data.data.payments.total
                      }
  
                  }
              }).catch((error) => {
                if (error.response && error.response.status === 401) {
                              this.$router.push({
                                  name: 'login'
                              });
                      }
              }).finally(() => {
                  this.loading = false;
                  this.searchingType = '';
              })
          },
          async checkStatus(id){
            this.statusSpinner = id;
            try{
              const url = `${this.dashBaseUrl}/getTransactionStatus/${id}`;
              const response = await this.axios.get(url,this.apiRequestConfig())
              if(response.status === 200){
                // if(response.data.status == true){
                //   toast.success("Payment Status "+ response.data.payment_status.payment_status_description);
                // }else{
                //   toast.error("Payment Status " + response.data.payment_status.payment_status_description + " (" + response.data.payment_status.error.code + ") ");
                // }
                this.getPayments(true);
                this.statusSpinner = false;
              }
            }catch(error){
              this.statusSpinner = false;
              if(error.response && error.response.status === 401){
                this.$router.push({name:'login'})
              }else if(error.response && error.response.status == 404){
                const errors = error.response.data.errors;
                let err = ""
                for(let i=0; i < errors.length; i++){
                    err +=errors[i]+'\n'
                }
                toast.error(err)
              }else if(error.response && error.response.status == 422){
                toast.error("Status: "+ error.response.data.payment_status + " description: "+ error.response.data.error_message)
              }
            }
  
          },
          async getBranches() {
              const url = this.baseUrl + "/branch?merchant_id=" + this.merchantID;
              this.axios.get(url, this.apiRequestConfig()).then((response) => {
                  if (response.status === 200) {
                      if (response.data.data !== null) {
                          const stores = response.data.data;
                          this.stores = stores;
                          this.strsoptions.push({
                                        "value": "",
                                        "name": "All Stores ("+ stores.length +")"
                          })
                          for (let i = 0; i < stores.length; i++) {
                              this.strsoptions.push({
                                  "value": stores[i].id,
                                  "name": stores[i].branch_name
                              })
                          }
                      }
                  }
              }).catch((error) => {
                if (error.response && error.response.status === 401) {
                              this.$router.push({
                                  name: 'login'
                              });
                      }
              })
  
          },
          getItem(item) {
              const OrderStatus = [];
              if (this.itemPosId !== '') {
                  const orderItems = item.items;
                  this.orderItem = [];
                  for (let i = 0; i < orderItems.length; i++) {
                      const pposId = orderItems[i].pos_id;
                      if (pposId.trim() == this.itemPosId.trim()) {
                          OrderStatus.push(orderItems[i]);
                      } else {
                          for (let x = 0; x < orderItems[i].sub_items.length; x++) {
                              const sposId = orderItems[i].sub_items[x].pos_id;
                              if (sposId.trim() == this.itemPosId.trim()) {
                                  OrderStatus.push({
                                      name: `${orderItems[i].sub_items[x].name} (${orderItems[i].name})`
                                  });
                              }
                          }
                      }
                  }
              }
              this.orderItem = OrderStatus;
          },

          onPageChange(page) {
              this.page = page
              this.getPayments(false);
          },
          onChangeRecordsPerPage() {
              this.getPayments(false);
              // ds
          }
      },
      mixins: [ReusableFunctionMixins, ReusableDataMixins]
  }
  </script>
  